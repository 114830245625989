<template>
  <div class="countdown">
    <div class="digits">
      <div class="inline-block relative h-[1em] overflow-hidden">
        <Transition name="slide-down">
          <div v-if="toggleDays">{{ toggleDays ? digits('days') : nextDigits('days') }}</div>
          <div v-else>{{ !toggleDays ? digits('days') : nextDigits('days') }}</div>
        </Transition>
      </div>
    </div>
    <Transition name="slide-down">
      <span v-if="true" class="explanation-label">Tage</span>
    </Transition>
    <div class="digits">
      <div class="inline-block relative h-[1em] overflow-hidden">
        <Transition name="slide-down">
          <div v-if="toggleHours">{{ toggleHours ? digits('hours') : nextDigits('hours') }}</div>
          <div v-else>{{ !toggleHours ? digits('hours') : nextDigits('hours') }}</div>
        </Transition>
      </div>
    </div>
    <Transition name="slide-down">
      <span v-if="true" class="explanation-label">Stunden</span>
    </Transition>
    <div class="digits">
      <div class="inline-block relative h-[1em] overflow-hidden">
        <Transition name="slide-down">
          <div v-if="toggleMinutes">{{ toggleMinutes ? digits('minutes') : nextDigits('minutes') }}</div>
          <div v-else>{{ !toggleMinutes ? digits('minutes') : nextDigits('minutes') }}</div>
        </Transition>
      </div>
    </div>
    <Transition name="slide-down">
      <span v-if="true" class="explanation-label">Minuten</span>
    </Transition>
    <div class="digits">
      <div class="inline-block relative h-[1em] overflow-hidden">
        <Transition name="slide-down">
          <div v-if="toggleSeconds">{{ toggleSeconds ? digits('seconds') : nextDigits('seconds') }}</div>
          <div v-else>{{ !toggleSeconds ? digits('seconds') : nextDigits('seconds') }}</div>
        </Transition>
      </div>
    </div>
    <Transition name="slide-down">
      <span v-if="true" class="explanation-label">Sekunden</span>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
  import { defineProps, PropType, onMounted, ref, onBeforeUnmount, onBeforeMount } from 'vue'

  const props = defineProps({
    date: {
      type: Date as PropType<Date>,
      required: true,
    },
  })

  const days = ref<number | undefined>(undefined)
  const toggleDays = ref(false)
  const hours = ref<number | undefined>(undefined)
  const toggleHours = ref(false)
  const minutes = ref<number | undefined>(undefined)
  const toggleMinutes = ref(false)
  const seconds = ref<number | undefined>(undefined)
  const toggleSeconds = ref(false)
  const interval = ref<number | undefined>(undefined)

  /**
   * Regularly updates the counter.
   */
  function count() {
    // Make sure, we do not overshoot.
    const dateDiffSeconds = Math.max(
      0,
      Math.floor((props.date.getTime() - Date.now()) / 1000),
    )
    const newSeconds = dateDiffSeconds % 60
    const newMinutes = Math.floor(dateDiffSeconds / 60) % 60
    const newHours = Math.floor(dateDiffSeconds / (60 * 60)) % 24
    const newDays = Math.floor(dateDiffSeconds / (60 * 60 * 24))
    if (newSeconds !== seconds.value) {
      seconds.value = newSeconds
      toggleSeconds.value = !toggleSeconds.value
    }
    if (newMinutes !== minutes.value) {
      minutes.value = newMinutes
      toggleMinutes.value = !toggleMinutes.value
    }
    if (newHours !== hours.value) {
      hours.value = newHours
      toggleHours.value = !toggleHours.value
    }
    if (newDays !== days.value) {
      days.value = newDays
      toggleDays.value = !toggleDays.value
    }
  }

  function digits(type: 'days' | 'hours' | 'minutes' | 'seconds') {
    switch (type) {
      case 'days':
        return days.value?.toString()
      case 'hours':
        return hours.value?.toString().padStart(2, '0')
      case 'minutes':
        return minutes.value?.toString().padStart(2, '0')
      case 'seconds':
        return seconds.value?.toString().padStart(2, '0')
    }
  }

  function nextDigits(type: 'days' | 'hours' | 'minutes' | 'seconds') {
    switch (type) {
      case 'days':
        return Math.max(0, ((days.value || 0) - 1)).toString()
      case 'hours':
        return Math.max(0, ((hours.value || 0) - 1)).toString().padStart(2, '0')
      case 'minutes':
        return Math.max(0, ((minutes.value || 0) - 1)).toString().padStart(2, '0')
      case 'seconds':
        return Math.max(0, ((seconds.value || 0) - 1)).toString().padStart(2, '0')
    }
  }

  onBeforeMount(() => {
    // To prevent a delay and have an initial count.
    count()
  })

  onMounted(() => {
    interval.value = setInterval(count, 1000)
  })

  onBeforeUnmount(() => {
    // Stop timer on unmounting.
    if (interval.value !== undefined) {
      clearInterval(interval.value)
    }
  })
</script>

<style lang="scss" scoped>
  .countdown {
    width: fit-content; // Windi does not seem to have a class for that...
    @apply flex justify-center justify-items-center mx-auto;

    .digits {
      font-family: "IBM Plex Mono", sans-serif;
      @apply text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-light flex items-end;
      line-height: 1em;
    }
    .explanation-label {
      @apply text-primary text-right self-end
        pr-2 lg:pr-4 xl:pr-5 pl-1
        text-tiny sm:text-sm md:text-base lg:text-lg xl:text-xl;
      height: fit-content;
      writing-mode: vertical-lr; // rotate text
    }
  }

  .slide-down-enter-active,
  .slide-down-leave-active {
    transition: transform 0.8s cubic-bezier(1,0,0,1), opacity 0.8s cubic-bezier(1,0,0,1);
  }

  .slide-down-enter-from {
    opacity: 0;
    transform: translateY(-200%);
  }

  .slide-down-leave-to {
    opacity: 0;
    transform: translateY(100%);
  }

  .slide-down-enter-to {
    opacity: 1;
    transform: translateY(-100%);
  }
</style>
