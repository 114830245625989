<template>
  <div class="prizes mt-12">
    <!-- <PrizeItem :side="Side.left" title="Hackerbags">
      Du erhältst einen Hackerbag mit Hardware, die Du mit nach Hause nehmen und behalten darfst. 🪛
    </PrizeItem> -->
    <PrizeItem
      :side="Side.right"
      title="Reise nach Potsdam"
    >
      Die Bestplatzierten gewinnen eine <strong>Reise nach Potsdam und Berlin</strong>.
      In einem Workshop vom 17.06.2024 - 18.06.2024 bieten wir Dir ein umfangreiches Besuchsprogramm vor Ort an spannenden und exklusiven Locations rund um Cybersecurity.
      Und das ganze zusammen mit den restlichen Gewinnenden des CTF!
      <!-- Natürlich bist Du rundum eingeladen: <strong>Reise, Hotel, Verpflegung und Events sind für Dich kostenlos</strong>. -->
    </PrizeItem>
    <PrizeItem
      :side="Side.left"
      title="Konferenz für Nationale Cybersicherheit"
    >
      Du gewinnst kostenlose Eintrittskarten für die
      <a
        href="https://hpi.de/veranstaltungen/hpi-veranstaltungen/2023/potsdamer-konferenz-fuer-nationale-cybersicherheit.html"
        target="_blank"
      >
        Potsdamer Konferenz für Nationale Cybersicherheit 2024
      </a>
      und kannst dort viele neue Kontakte knüpfen.
    </PrizeItem>
  </div>
</template>

<script lang="ts" setup>
  import { provide } from 'vue'

  import PrizeItem, { Side } from './PrizeItem.vue'

  provide('price-item-counter', 0)
</script>

<style lang="scss" scoped>
  .prizes-line {
    // Generate a vertical line.
    background-image: linear-gradient(#fff, #fff);
    background-size: 2px 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
</style>
