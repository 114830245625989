<template>
  <div
    ref="itemRef"
    class="accordion-item first:rounded-t-lg last:rounded-b-lg border border-background"
    :class="{
      'bg-dark-200': !isOpen,
      'bg-primaryBg': isOpen,
    }"
  >
    <div class="accordion-header">
      <button
        class="w-full py-4 px-4 flex justify-between items-center gap-x-2"
        @click="isOpen = !isOpen"
      >
        <h2 class="font-bold text-left text-lg mb-0">
          {{ title }}
        </h2>
        <div
          class="w-5 h-5 transform-gpu transition"
          :class="{
            'rotate-0': !isOpen,
            'rotate-180': isOpen,
          }"
        >
          <ChevronDownIcon />
        </div>
      </button>
    </div>
    <Transition
      name="expand"
      @enter="enterExpandAnimation"
      @after-enter="afterEnterExpand"
      @leave="leaveExpandAnimation"
    >
      <div
        v-if="isOpen"
      >
        <div class="accordion-body py-4 px-5">
          <slot />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
  import { ChevronDownIcon } from '@heroicons/vue/24/solid'
  import { ref, watch, onMounted, onUnmounted } from 'vue'

  const emit = defineEmits(['toggle'])

  const props = defineProps({
    title: {
      type: String,
      required: true,
    },
    openWithAnchor: {
      type: Boolean,
      required: false,
      default: true,
    },
  })

  const isOpen = ref(false)
  const itemRef = ref<HTMLDivElement | null>(null)
  const windowHash = ref<string| null>(null)

  const onWindowHashChange = () => {
    if (!props.openWithAnchor) {
      return
    }
    windowHash.value = window.location.hash
  }

  onMounted(() => {
    window.addEventListener('hashchange', onWindowHashChange)
    windowHash.value = window.location.hash
  })
  onUnmounted(() => {
    window.removeEventListener('hashchange', onWindowHashChange)
  })

  watch(() => windowHash.value, (newHash) => {
    const isCurrentAnchor = !!itemRef.value && itemRef.value.id !== '' && `#${itemRef.value.id}` === newHash
    if (!props.openWithAnchor || !isCurrentAnchor) {
      return
    }
    isOpen.value = true
  })

  watch(() => isOpen.value, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      emit('toggle')
    }
  })

  // javascript-based animation inspired by
  // https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
  function enterExpandAnimation(element: Element) {
    const htmlElement = element as HTMLElement
    const width = getComputedStyle(htmlElement).width

    // Hack to get the height if visible.
    htmlElement.style.width = width
    htmlElement.style.position = 'absolute'
    htmlElement.style.visibility = 'hidden'
    htmlElement.style.height = 'auto'
    const height = getComputedStyle(htmlElement).height

    htmlElement.style.width = ''
    htmlElement.style.position = ''
    htmlElement.style.visibility = ''
    htmlElement.style.height = '0px'

    // Force repaint to make sure the
    // animation is triggered correctly.
    // eslint-disable-next-line no-void
    void getComputedStyle(htmlElement).height

    // Trigger the animation.
    // We use `requestAnimationFrame` because we need
    // to make sure the browser has finished
    // painting after setting the `height`
    // to `0` in the line above.
    requestAnimationFrame(() => {
      htmlElement.style.height = height
    })
  }

  function afterEnterExpand(element: Element) {
    const htmlElement = element as HTMLElement
    htmlElement.style.height = 'auto'
  }

  function leaveExpandAnimation(element: Element) {
    const htmlElement = element as HTMLElement
    const height = getComputedStyle(htmlElement).height

    htmlElement.style.height = height

    // Force repaint to make sure the
    // animation is triggered correctly.
    // eslint-disable-next-line no-void
    void getComputedStyle(htmlElement).height

    requestAnimationFrame(() => {
      htmlElement.style.height = '0px'
    })
  }
</script>

<style lang="scss" scoped>
$expand-animation-duration: 200ms;
$animation-easing: ease-in-out;

.accordion-item {
  transition: background-color $expand-animation-duration $animation-easing;
}

.expand-enter-active,
.expand-leave-active {
  transition: height $expand-animation-duration $animation-easing;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
