<template>
  <Header :menuItems="menuItems" @menu-item-click="handleMenuItemClick" />
  <LandingHeader id="mach-mit" class="ctf-section" />
  <!-- This small top padding is necessary in order to allow
      the negative margin for the overlay. -->
  <content-section id="darum-gehts" class="ctf-section pt-1" caption="Warum Du dabei sein solltest" title="Let the Hackation begin! 🏝️">
    <template v-slot:preContainer>
      <StoryOverlay class="mb-8" />
    </template>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-8">
      <Feature title="🏨 Das Hotel auf HoneyPot Island">
        Per Preisausschreiben wurdest Du ausgewählt, nach HoneyPot Island zu reisen und das neue Luxushotel zu testen.
        <strong>Endlich mal Urlaub! 🧳</strong>
        Doch schon als Du ankommst merkst Du, dass das technisch gut ausgestattete Hotel vor Sicherheitslücken nur so strotzt.
        Die Hotelmanagerin bittet Dich nun um Hilfe: Dein Urlaub wird zur Hackation, denn jetzt "testest" Du alle Systeme.
      </Feature>
      <Feature title="🧑‍💻 Die Challenges">
        Auf HoneyPot Island gibt es viele Challenges, die dir auch in deinem nächsten Urlaub begegnen könnten:
        Öffne schlecht gesicherte Türen, finde mehr über das unsichere WLAN heraus und besorge dir trotz vollem Kalender einen Termin zu einer entspannenden Massage.
      </Feature>
      <Feature title="🕸️ Vernetze Dich">
        Wie im Urlaub hast Du auch bei uns die Möglichkeit tolle neue Menschen kennenzulernen:
        während des CTF oder im Anschluss als einer der <strong>Top 10 Teilnehmenden</strong> bei einem Cybersecurity-Workshop vor Ort in Potsdam!
        Interessiert? Dann schau Dir doch mal unsere <a href="#prizes">Preise</a> an!
      </Feature>
      <Feature title="🤯 Realistischer Bezug">
        Cybersecurity betrifft Dich nicht (besonders nicht im Urlaub)?
        Doch! Denn auch Hotels sind attraktive Ziele für Hacks, weil sie viele persönliche Daten über die Gäste speichern.
        Aber auch Du als Gast können gefährdet sein, sei es durch das offene WLAN oder USB-Ladestellen für das eigene Smartphone.
        Deshalb brauchen wir Dich, um nicht nur unser virtuelles Hotel, sondern auch die echte Welt ein bisschen sicherer zu machen!
      </Feature>
    </div>
  </content-section>
  <content-section id="teaser-video" class="ctf-section" caption="Teaser Video" title="Sieh Dir den offiziellen Teaser an.">
    <Teaser />
  </content-section>
  <content-section
    id="prizes"
    class="ctf-section"
    caption="Preispool"
    title="Finde Flags, sammle Punkte und gewinne fantastische Preise."
  >
    <Prizes />
  </content-section>
  <content-section
    id="challenges"
    class="ctf-section"
    caption="Hacking"
    :title="`Löse mehr als ${Math.floor(CTF_NUMBER_CHALLENGES / 10) * 10} Challenges.`"
  >
    <p class="mb-4">
      Bei den {{ CTF_TITLE_DATIV }} erwarten Dich Challenges verschiedenster Kategorien.
      Zu Beginn sind {{ CTF_NUMBER_CHALLENGES_START }} Challenges zugänglich.
      Nach und nach kannst Du Dich durch das Szenario hacken und alle Challenges freischalten.
    </p>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <ChallengeCard title="Web">
        <template v-slot:icon>
          <GlobeAltIcon />
        </template>
      </ChallengeCard>
      <ChallengeCard title="Forensics">
        <template v-slot:icon>
          <FingerPrintIcon />
        </template>
      </ChallengeCard>
      <ChallengeCard title="Network">
        <template v-slot:icon>
          <ServerStackIcon />
        </template>
      </ChallengeCard>
      <ChallengeCard title="Cryptography">
        <template v-slot:icon>
          <LockClosedIcon />
        </template>
      </ChallengeCard>
      <ChallengeCard title="Hardware">
        <template v-slot:icon>
          <CpuChipIcon />
        </template>
      </ChallengeCard>
      <ChallengeCard title="Pwn">
        <template v-slot:icon>
          <CommandLineIcon />
        </template>
      </ChallengeCard>
    </div>
  </content-section>
  <content-section id="timeline" class="ctf-section" caption="Timeline" title="Der Weg zum Erfolg.">
    <Timeline
      :events="[
        {
          title: 'Start der Registrierungsphase',
          description: `Du kannst Dich für die ${CTF_TITLE} registrieren.`,
          startDate: new Date(2024, 2, 15, 12, 0, 0),
        },
        {
          title: 'Wettbewerb',
          description: 'Während des CTF-Events kannst Du Punkte sammeln und Dich für das Finale qualifizieren. '
            + 'Wir haben abwechslungsreiche Challenges für Dich vorbereitet, die sowohl auf Einsteiger als auch Fortgeschrittene zugeschnitten sind.',
          startDate: CTF_START,
          endDate: CTF_END,
        },
        {
          title: 'Recap Phase',
          description: 'Nach Ende des Wettbewerbs können die Challenges außerhalb der Wertung zwei Wochen lang weiter genutzt werden. '
            + 'In dieser Zeit kannst du dich zum Beispiel mit anderen Teilnehmenden zu den Challenges austauschen.',
          startDate: CTF_END,
          endDate: CTF_POST_PLAY_TIME_END,
        },
        {
          title: 'Finale',
          description: 'Die Bestplatzierten werden zu einem Cybersicherheits-Workshop nach Potsdam eingeladen. '
            + 'Ein spannendes Vor-Ort Programm mit Ausflügen zu interessanten Partnern und die Cybersicherheitskonferenz erwartet Dich.',
          startDate: new Date(2024, 5, 17, 9, 0, 0),
          endDate: new Date(2024, 5, 20, 20, 0, 0),
        },
      ]"
    />
  </content-section>
  <content-section class="ctf-section" caption="Anmeldung" title="Registriere Dich">
    <p class="pb-2">
      Auf unserer CTF-Plattform findest Du den Zugang zu den Challenges.
      Auf unserem Discord Server kannst Du Dich außerdem austauschen und Fragen stellen.
    </p>
    <div class="w-full text-center">
      <RegisterButton class="mx-auto mr-2 mt-4" />
      <JoinDiscordButton class="mx-auto ml-2 mt-4" />
    </div>
  </content-section>
  <content-section id="faq" class="ctf-section" caption="FAQ" title="Häufige Fragen">
    <FAQ />
  </content-section>
  <content-section id="the-team" class="ctf-section" caption="Cybersecurity mit ❤️" title="Das Team hinter dem CTF.">
    <TheTeam />
  </content-section>
  <Footer />
</template>

<script lang="ts" setup>
  import {
    GlobeAltIcon,
    FingerPrintIcon,
    ServerStackIcon,
    LockClosedIcon,
    CommandLineIcon,
    CpuChipIcon,
  } from '@heroicons/vue/24/outline'
  import { computed, onMounted, onUpdated, ref } from 'vue'

  import ChallengeCard from '@/components/landing-page/ChallengeCard.vue'
  import ContentSection from '@/components/landing-page/ContentSection.vue'
  import FAQ from '@/components/landing-page/FAQ.vue'
  import Feature from '@/components/landing-page/Feature.vue'
  import Footer from '@/components/landing-page/Footer.vue'
  import Header from '@/components/landing-page/Header.vue'
  import JoinDiscordButton from '@/components/landing-page/JoinDiscordButton.vue'
  import LandingHeader from '@/components/landing-page/LandingHeader.vue'
  import Prizes from '@/components/landing-page/Prizes.vue'
  import RegisterButton from '@/components/landing-page/RegisterButton.vue'
  import StoryOverlay from '@/components/landing-page/StoryOverlay.vue'
  import Teaser from '@/components/landing-page/Teaser.vue'
  import TheTeam from '@/components/landing-page/TheTeam.vue'
  import Timeline from '@/components/landing-page/Timeline.vue'
  import {
    CTF_NUMBER_CHALLENGES,
    CTF_NUMBER_CHALLENGES_START,
    CTF_TITLE,
    CTF_TITLE_DATIV,
    CTF_START,
    CTF_END,
    CTF_POST_PLAY_TIME_END,
  } from '@/constants'

  const animationIntersectionObserver = ref<IntersectionObserver | undefined>(undefined)
  const sectionScrollOverserver = ref<IntersectionObserver | undefined>(undefined)

  /**
   * Sets up a nice animation for tagged elements.
   */
  function setupScrollAnimation() {
    if (animationIntersectionObserver.value) {
      animationIntersectionObserver.value.disconnect()
    }

    const animationObjects = document.querySelectorAll('.animate-on-scroll')

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.rootBounds && entry.boundingClientRect.bottom >= entry.rootBounds.bottom) {
            entry.target.classList.add('animate-on-scroll-show')
          } else {
            // Make sure that - if we reload the page and start in the middle - we do not
            // get the animations for elements above.
            entry.target.classList.add('animate-on-scroll-show-immediate')
          }
        }
      })
    }
    const options = {}

    const scrollInstersectionObserver = new IntersectionObserver(callback, options)
    animationObjects.forEach((animationObject) => {
      scrollInstersectionObserver.observe(animationObject)
    })

    animationIntersectionObserver.value = scrollInstersectionObserver
  }

  const visible = ref<Element | null>(null)

  const menuItems = computed(() => {
    const items: { title: string, id: string, active: boolean }[] = [
      {
        title: 'Darum geht\'s',
        id: 'darum-gehts',
        active: false,
      },
      {
        title: 'Teaser',
        id: 'teaser-video',
        active: false,
      },
      {
        title: 'Preise',
        id: 'prizes',
        active: false,
      },
      {
        title: 'Challenges',
        id: 'challenges',
        active: false,
      },
      {
        title: 'Timeline',
        id: 'timeline',
        active: false,
      },
      {
        title: 'FAQ',
        id: 'faq',
        active: false,
      },
      {
        title: 'Das Team',
        id: 'the-team',
        active: false,
      },
    ]

    const id = visible.value?.id
    if (!id) {
      return items
    }
    const item = items.find((item) => item.id === id)
    if (item) {
      item.active = true
    }

    return items
  })

  const handleMenuItemClick = (item: { id: string }) => {
    const el = document.querySelector(`#${item.id}`)
    if (!el) {
      return
    }
    el.scrollIntoView({ behavior: 'smooth' })
  }

  /**
   * Sets up a detection for scrolled sections
   */
  function setupSectionScrollDetection() {
    if (sectionScrollOverserver.value) {
      sectionScrollOverserver.value.disconnect()
    }

    const sections = document.querySelectorAll('.ctf-section')

    const callback: IntersectionObserverCallback = (entries) => {
      const mainEntry = entries.findLast((entry) => entry.isIntersecting)

      if (mainEntry) {
        visible.value = mainEntry.target
      }
    }

    const scrollInstersectionObserver = new IntersectionObserver(
      callback,
      {
        // The element which covers the middle is expected to be the main / active content.
        rootMargin: '0px 0px -50% 0px',
      },
    )
    sections.forEach((animationObject) => {
      scrollInstersectionObserver.observe(animationObject)
    })

    sectionScrollOverserver.value = scrollInstersectionObserver
    scrollInstersectionObserver.takeRecords()
  }

  onMounted(() => {
    setupScrollAnimation()
    setupSectionScrollDetection()
  })

  onUpdated(() => {
    setupScrollAnimation()
    setupSectionScrollDetection()
  })
</script>

<style lang="scss">
  @import "style/main.scss";
  @import "style/landing-page-animation.scss";

  #cyber-games-wrapper {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
    @apply bg-background text-standardtext;
    margin: 0px;
  }

  /* Account for header when auto scrolling. */
  * {
    scroll-margin-top: $headerHeight;
  }
</style>

<style lang="scss" scoped>
  .ctf-section {
    &:nth-child(odd) {
      @apply bg-background;
    }
    &:nth-child(even) {
      @apply bg-dark-800;
    }
  }
</style>
