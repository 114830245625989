<template>
  <div
    class="story-overlay relative text-center rounded-lg bg-dark-500 bg-opacity-95 w-95/100 md:w-9/10 xl:w-8/10 max-w-8xl
      py-8 shadow-lg left-1/2 z-10 -mt-52"
  >
    <div class="container relative mx-auto px-6 sm:px-12 md:px-24 max-w-9xl">
      <h2 class="my-4 text-xl sm:text-2xl md:text-4xl">Hast Du das Zeug, die Welt sicherer zu machen?</h2>
      <div v-if="!ctfDidEnd">
        <p class="my-2 mx-auto max-w-200 text-muted text-justify">
          Zeige Dein Können bei den zweiten <strong>{{ CTF_TITLE_DATIV }}</strong> und beweise Dich während Deiner Hackation 🏝️.
          Die {{ CTF_TITLE }} sind ein
          <strong><a href="#what-is-a-ctf">Capture the Flag</a></strong> Wettbewerb,
          bei dem Du Challenges im Bereich Cybersicherheit lösen kannst.
          Bisher wenig Erfahrung?
          <strong>Kein Problem!</strong>
          Bei uns kannst Du ohne Druck auch teilnehmen, wenn Du nicht bereits Cybersicherheit studiert hast.
          <br>
          <span v-if="!ctfDidStart" class="inline-block font-bold mt-2 text-xl">Der Countdown zum Start läuft…</span>
          <span v-else class="inline-block font-bold mt-2 text-xl">Der Wettbewerb läuft noch…</span>
        </p>
        <CountDown v-if="!ctfDidStart" class="mx-auto mb-4 md:mb-8 mt-4 md:mt-8" :date="CTF_START" />
        <CountDown v-else class="mx-auto mb-4 md:mb-8 mt-4 md:mt-8" :date="CTF_END" />
        <p class="mb-4 md:mb-6 mx-auto max-w-200 text-muted text-right">
          ...und hack Dich zu unseren <a href="#prizes">Preisen</a><!-- im Wert von über <span class="text-primary text-bold">15.000&nbsp;&euro;</span>-->!
        </p>
        <div class="flex justify-center gap-3 flex-wrap w-full">
          <RegisterButton />
          <JoinDiscordButton />
          <ContactEmailButton />
        </div>
      </div>
      <div v-else>
        <p class="my-4 text-lg sm:text-xl md:text-2xl">
          Der Wettbewerb ist beendet.
          Ein riesengroßes <b>Dankeschön</b>, dass Du dabei warst! 🚀
        </p>
        <p>
          <span v-if="showPostPlayTimeMessage">
            Aber nicht traurig sein: Du kannst <a :href="REGISTER_LINK" target="_blank">noch zwei Wochen weiterhacken</a>&nbsp;🧑‍💻.
          </span>
          Du möchtest die nächsten {{ CTF_TITLE }} nicht verpassen? Tritt einfach unserem Discord-Server bei,
          um Benachrichtigungen für die nächsten {{ CTF_TITLE }} zu erhalten.
        </p>
        <JoinDiscordButton class="mt-4" />
      </div>
    </div>
    <Fireworks
      ref="fireworksRef"
      class="left-0 top-0 right-0 bottom-0 absolute -z-1 pointer-events-none"
      :autostart="false"
    />
  </div>
</template>

<script lang="ts" setup>
  import Fireworks from '@fireworks-js/vue'
  import { onBeforeUnmount, onMounted, ref } from 'vue'

  import ContactEmailButton from './ContactEmailButton.vue'
  import CountDown from './CountDown.vue'
  import JoinDiscordButton from './JoinDiscordButton.vue'
  import RegisterButton from './RegisterButton.vue'

  import {
    CTF_END,
    CTF_POST_PLAY_TIME_END,
    CTF_START,
    CTF_TITLE,
    CTF_TITLE_DATIV,
    FIREWORKS_ANIMATION_DURATION,
    REGISTER_LINK,
  } from '@/constants'

  const fireworksRef = ref<null | InstanceType<typeof Fireworks>>(null)

  const ctfDidStart = ref(Date.now() >= CTF_START.getTime())
  const ctfDidEnd = ref(Date.now() >= CTF_END.getTime())
  const ctfStartTimeout = ref<NodeJS.Timeout | null>(null)
  const ctfEndTimeout = ref<NodeJS.Timeout | null>(null)
  const fireworksStopTimeout = ref<NodeJS.Timeout | null>(null)
  const showPostPlayTimeMessage = ref(new Date() < CTF_POST_PLAY_TIME_END)
  const dontShowPostPlayTimeMessageTimeout = ref<NodeJS.Timeout | null>(null)

  const startFireworksIfNecessary = () => {
    if (fireworksStopTimeout.value !== null) {
      clearTimeout(fireworksStopTimeout.value)
    }
    fireworksRef.value?.updateOptions({
      intensity: 12,
      acceleration: 1,
      traceLength: 6,
      opacity: 0.75,
      explosion: 4,
      lineWidth: {
        trace: {
          min: 0.5,
          max: 3.0,
        },
      },
    })
    // The end time of fireworks when the competition started.
    const fireworksStartCompetitionStop = CTF_START.getTime() + FIREWORKS_ANIMATION_DURATION
    const fireworksStartCompetitionStopTimeLeft = fireworksStartCompetitionStop - Date.now()
    const fireworkEndCompetitionStop = CTF_END.getTime() + FIREWORKS_ANIMATION_DURATION
    const fireworkEndCompetitionStopTimeLeft = fireworkEndCompetitionStop - Date.now()

    let fireworksStopTimeLeft: number | null = null
    if (Date.now() > CTF_START.getTime() && fireworksStartCompetitionStopTimeLeft > 0) {
      fireworksStopTimeLeft = fireworksStartCompetitionStopTimeLeft
    } else if (Date.now() > CTF_END.getTime() && fireworkEndCompetitionStopTimeLeft > 0) {
      fireworksStopTimeLeft = fireworkEndCompetitionStopTimeLeft
    }

    if (fireworksStopTimeLeft !== null && fireworksStopTimeLeft > 0) {
      fireworksRef.value?.start()
      fireworksStopTimeout.value = setTimeout(
        () => {
          fireworksRef.value?.waitStop()
        },
        Math.min(maxSafeTimeout, fireworksStopTimeLeft),
      )
    }
  }

  /**
   * The issue with setTimeout is that the callback would be triggered immediately,
   * when delay is larger than this value.
   * See: https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
   */
  const maxSafeTimeout = (2 ** 31) - 1

  onMounted(() => {
    // Set a timeout to trigger the switch when the competition starts.
    const timeToStart = CTF_START.getTime() - Date.now()
    if (timeToStart > 0 && timeToStart < maxSafeTimeout) {
      ctfStartTimeout.value = setTimeout(
        () => {
          ctfDidStart.value = true
          startFireworksIfNecessary()
        },
        timeToStart,
      )
    }

    // Set a timeout to trigger the switch when the competition ends.
    const timeToEnd = CTF_END.getTime() - Date.now()
    if (timeToStart < 0 && timeToEnd > 0 && timeToEnd < maxSafeTimeout) {
      ctfEndTimeout.value = setTimeout(
        () => {
          ctfDidEnd.value = true
          startFireworksIfNecessary()
        },
        timeToEnd,
      )
    }
    startFireworksIfNecessary()

    // Set a timeout to hide post play time message.
    const timeToPostPlayTimeEnd = CTF_POST_PLAY_TIME_END.getTime() - Date.now()
    if (timeToPostPlayTimeEnd > 0 && timeToPostPlayTimeEnd < maxSafeTimeout) {
      dontShowPostPlayTimeMessageTimeout.value = setTimeout(
        () => { showPostPlayTimeMessage.value = false },
        timeToPostPlayTimeEnd,
      )
    }
  })

  onBeforeUnmount(() => {
    if (ctfStartTimeout.value !== null) {
      clearTimeout(ctfStartTimeout.value)
      ctfStartTimeout.value = null
    }
    if (ctfEndTimeout.value !== null) {
      clearTimeout(ctfEndTimeout.value)
      ctfEndTimeout.value = null
    }
    if (fireworksStopTimeout.value !== null) {
      clearTimeout(fireworksStopTimeout.value)
      fireworksStopTimeout.value = null
    }
    if (dontShowPostPlayTimeMessageTimeout.value !== null) {
      clearTimeout(dontShowPostPlayTimeMessageTimeout.value)
      dontShowPostPlayTimeMessageTimeout.value = null
    }
  })
</script>

<style lang="scss" scoped>
  .story-overlay {
    transform: translateX(-50%);
  }
</style>
