<template>
  <!-- When the screen is smaller than medium, then always use the right side. -->
  <div
    v-if="side === Side.left"
    class="w-full grid grid-cols-1 lg:grid-cols-2 items-center auto-rows-auto auto-rows-min relative"
  >
    <div class="row-span-2 pl-8 lg:pr-8 flex items-center justify-end">
      <div
        class="rounded bg-dark-200 py-4 px-4 sm:px-8 mr-auto lg:ml-auto lg:max-w-9/10 my-4 border-primary border-t-4 animate-on-scroll"
        data-animation-direction="right"
      >
        <PrizeItemContent :title="title">
          <slot />
        </PrizeItemContent>
      </div>
    </div>
    <div class="bg-dark-200 w-1 h-full absolute left-0 lg:left-1/2 -ml-0.5" />
    <div class="bg-dark-200 rounded-full -m-2 w-4 h-4 absolute left-0 lg:left-1/2 top-1/2" />
  </div>
  <div
    v-else
    class="w-full grid grid-cols-1 lg:grid-cols-2 items-center relative"
  >
    <div class="row-span-2 lg:col-start-2 pl-8 flex items-center justify-end">
      <div
        class="rounded bg-dark-200 py-4 px-4 sm:px-8 mr-auto w-full lg:w-auto lg:max-w-9/10 my-4  border-primary border-t-4 animate-on-scroll"
        data-animation-direction="left"
      >
        <PrizeItemContent :title="title">
          <slot />
        </PrizeItemContent>
      </div>
    </div>
    <div class="bg-dark-200 w-1 h-full absolute left-0 lg:left-1/2 -ml-0.5" />
    <div class="bg-dark-200 rounded-full -m-2 w-4 h-4 absolute left-0 lg:left-1/2 top-1/2" />
  </div>
</template>

<script lang="ts">
  import { PropType, defineProps } from 'vue'

  import PrizeItemContent from './PrizeItemContent.vue'

  export enum Side {
    'left',
    'right'
  }
</script>

<script lang="ts" setup>
  defineProps({
    side: {
      type: Number as PropType<Side>,
      validator: (value: number) => {
        return [Side.left, Side.right].includes(value)
      },
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  })
</script>
