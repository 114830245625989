<template>
  <div class="animate-on-scroll">
    <iframe
      v-if="showVideo"
      class="mx-auto w-full aspect-video max-w-6xl rounded-md"
      :src="CTF_YOUTUBE_VIDEO"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    />
    <div
      v-else class="mx-auto w-full max-w-6xl bg-gray-700 rounded-md relative teaser-data-protection"
    >
      <div class="background-image absolute w-full h-full rounded-md" />
      <div
        class="w-full h-auto flex flex-wrap data-protection-text break-words
        justify-center items-center content-center gap-4 px-8 md:px-4 p-y-8 backdrop-blur bg-black/50 backdrop-filter aspect-video"
      >
        <h4 class="w-full text-center">Externer Inhalt</h4>
        <p class="block w-full md:max-w-8/10 lg:max-w-8/10 xl:max-w-7/10 text-justify">
          Dieses Video ist im erweiterten Datenschutzmodus von Youtube eingebunden, der das Setzen von Youtube-Cookies solange blockiert,
          bis ein aktiver Klick auf die Wiedergabe erfolgt.
          Mit Klick auf den Wiedergabe-Button erteilst Du Deine Einwilligung darin, dass Youtube auf dem von Dir verwendeten Endgerät Cookies setzt,
          die auch einer Analyse des Nutzungsverhaltens zu Marktforschungs- und Marketing-Zwecken dienen können.
          Näheres zur Cookie-Verwendung durch Youtube findest Du
          in der Cookie-Policy von Google unter <a href="https://policies.google.com/technologies/types?hl=de">https://policies.google.com/technologies/types?hl=de</a>.
        </p>
        <div class="w-full">
          <button
            class="block mx-auto py-3 px-[1.125rem] rounded-md leading-none bg-primaryBg hover:text-dark-500 hover:bg-primaryBgHover"
            @click="showVideo=true"
          >
            <div class="w-[1em] h-[1em] inline-block align-bottom mr-1">
              <PlayIcon />
            </div>
            Zeig mir das Video
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PlayIcon } from '@heroicons/vue/24/outline'
  import { ref } from 'vue'

  import { CTF_YOUTUBE_VIDEO } from '@/constants'

  const showVideo = ref(false)
</script>

<style lang="scss">
.teaser-data-protection {
  .background-image {
    background-image: url('@/images/teaser-frame.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
</style>
