<template>
  <header class="pt-12 md:pt-18 lg:pt-24 pb-64 relative overflow-hidden">
    <video
      ref="video"
      autoplay muted loop playsinline
      class="absolute w-full top-0 bottom-0 my-auto min-h-full min-w-full pointer-events-none object-cover"
    >
      <source src="../../images/hacking_blurred_720.mp4" type="video/mp4">
    </video>
    <div class="container mx-auto">
      <Logo class="relative z-20 mx-auto max-w-[50rem] w-[70%]" />
    </div>
  </header>
</template>

<script lang="ts" setup>
  import { ref, onMounted } from 'vue'

  import Logo from '@/components/Logo.vue'

  const video = ref<HTMLVideoElement | null>(null)

  onMounted(() => {
    setTimeout(() => {
      if (!video.value) {
        return
      }
      // eslint-disable-next-line no-void
      void video.value.play()
    }, 1500)
  })
</script>

<style lang="scss" scoped>
  .title-heading {
    @apply pt-2 pb-2 text-center bg-clip-text text-transparent bg-gradient-to-br from-error to-blue-600
      text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl ;
    font-family: "Jadefedga", sans-serif;
  }
  .subheading {
    @apply pt-1 sm:pt-3 lg:pt-5 text-center text-standardtext
      text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl relative z-20;
  }
</style>
<style>
*::-webkit-media-controls {
  display: none !important;
  -webkit-appearance: none !important;
}
*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none !important;
}
*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none !important;
}
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none !important;
}
</style>
