<template>
  <div id="timeline" class="timeline-wrapper flex flex-wrap">
    <div v-for="event, idx in sortedEvents" :key="idx" class="flex w-full items-center gap-x-8">
      <div
        class="h-full flex items-center flex-col transform-gpu"
        :class="{
          'translate-y-[calc(50%-0.5rem-1rem)]': idx === 0
        }"
      >
        <div
          v-if="idx > 0"
          class="w-1 border-2"
          :class="{
            'dark-border': !shouldLightTimelineLine(sortedEvents[idx - 1]),
            'border-primary': shouldLightTimelineLine(sortedEvents[idx - 1]),
            'h-[calc(50%-0.5rem-1rem)]': idx < sortedEvents.length - 1,
            // As the last one does not have a bottom margin.
            'h-[calc(50%-0.5rem)]': idx === sortedEvents.length - 1,
          }"
        /><div
          class="h-4 w-4 rounded-full"
          :class="{'dark-bg': !shouldLightTimelineDot(event), 'bg-primary': shouldLightTimelineDot(event)}"
        />
        <div
          v-if="idx !== sortedEvents.length - 1"
          class="h-[calc(50%-0.5rem+1rem)] w-1 border-2"
          :class="{'dark-border': !shouldLightTimelineLine(event), 'border-primary': shouldLightTimelineLine(event)}"
        />
      </div>
      <div
        data-animation-direction="left"
        class="timeline-box relative dark-bg py-4 px-4 sm:px-8 flex-grow shadow-md shadow-dark-400 rounded-md animate-on-scroll"
        :class="{
          'mb-8': idx < sortedEvents.length - 1,
          'mb-0': idx === sortedEvents.length - 1,
        }"
      >
        <h5 class="timeline-box-title font-bold text-lg sm:text-xl break-words">{{ event.title }}</h5>
        <h6 class="text-sm mb-2">({{ dateTitle(event) }})</h6>
        <p class="timeline-box-content text-muted">{{ event.description }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, defineProps, PropType } from 'vue'
  interface TimelineEvent {
    title: string
    description: string
    startDate: Date,
    endDate?: Date
  }

  const props = defineProps({
    events: {
      type: Array as PropType<TimelineEvent[]>,
      required: true,
      validator: (events: TimelineEvent[]) => {
        const isTimelineEvent = (obj: object): obj is TimelineEvent => {
          const possibleEvent = obj as TimelineEvent
          return typeof possibleEvent.title === 'string' &&
            typeof possibleEvent.description === 'string' &&
            possibleEvent.startDate instanceof Date &&
            (!possibleEvent.endDate || possibleEvent.endDate instanceof Date)
        }
        if (!events.every(isTimelineEvent)) {
          return false
        }
        // Find overlaps (which are not allowed).
        const foundDates: ({ time: number, tag: 'start' | 'end' | 'single' })[] = []
        for (const event of events) {
          if (event.endDate) {
            foundDates.push({ time: event.startDate.getTime(), tag: 'start' })
            foundDates.push({ time: event.endDate.getTime(), tag: 'end' })
            continue
          }
          foundDates.push({ time: event.startDate.getTime(), tag: 'single' })
        }
        foundDates.sort((a, b) => a.time - b.time)

        let openInterval = 0
        for (const foundDate of foundDates) {
          if (foundDate.tag === 'start') {
            if (openInterval > 0) {
              return false
            }
            openInterval += 1
          } else if (foundDate.tag === 'end') {
            if (openInterval <= 0) {
              return false
            }
            openInterval -= 1
          } else {
            if (openInterval > 0) {
              return false
            }
          }
        }

        return true
      },
    },
  })

  function shouldLightTimelineLine(event: TimelineEvent) {
    if (event.endDate) {
      return Date.now() > event.endDate.getTime()
    }
    return Date.now() > event.startDate.getTime()
  }

  function shouldLightTimelineDot(event: TimelineEvent) {
    return Date.now() > event.startDate.getTime()
  }

  function formatDate(date: Date) {
    return date.toLocaleString(
      'de-DE',
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        dayPeriod: 'long',
        minute: '2-digit',
        timeZoneName: 'short',
      },
    )
  }

  function dateTitle(event: TimelineEvent) {
    if (event.endDate) {
      return `${formatDate(event.startDate)} - ${formatDate(event.endDate)}`
    }
    return formatDate(event.startDate)
  }

  // We can sort the events just by start date here, as we made sure, there are no overlaps.
  const sortedEvents = computed(() => [...props.events].sort((a, b) => a.startDate.getTime() - b.startDate.getTime()))
</script>

<style lang="scss" scoped>
  .dark-bg {
    @apply bg-dark-300;
  }
  .dark-border {
    @apply border-dark-300;
  }

  .timeline-box {
    .timeline-box-content::after {
      content: " ";
      @apply border-l-transparent border-t-transparent border-b-transparent border-r-dark-300 border-solid
        -mt-3 -ml-6 border-[12px] left-0 top-1/2 absolute;
    }
  }
</style>
